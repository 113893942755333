import React from 'react'
import "./TechStack.css"

// const formated = {
//     maxWidth:"10%",
//     maxHeight:"10%",
//     minWidth:"5%",
//     maxHeight:"5%",
//     backgroundColor:"white"
    
// }
const TechStack = () => {
    return (
        <></>
        // <div className="container">
        //     <img className="circle" src="https://img.icons8.com/color/96/000000/html-5--v2.png" alt=""/>
        //     <img className="circle" src="https://img.icons8.com/ios-filled/100/000000/javascript.png" alt=""/>
        //     <img className="circle" src="https://img.icons8.com/fluent-systems-filled/96/000000/github.png" alt=""/>
        //     <img className="circle" src="https://img.icons8.com/fluent/96/000000/unity.png" alt=""/>
        //     <img className="circle" src="https://img.icons8.com/officel/160/000000/react.png" alt=""/>
        //     <img className="circle" src="https://img.icons8.com/ios-filled/100/000000/django.png" alt=""/>
        //     <img className="circle" src="https://img.icons8.com/color/96/000000/python.png" alt=""/>
        //     <img className="circle" src="https://img.icons8.com/color/96/000000/postgreesql.png" alt=""/>
        //     <img className="circle" src="https://img.icons8.com/metro/52/000000/mysql.png" alt=""/>
        //     <img className="circle" src="https://img.icons8.com/color/96/000000/firebase.png" alt=""/>
        // </div>
    )
}

export default TechStack
