import React, { useEffect, useState } from 'react'
import './Word.css'
import profile from '../../static/20201128_144908.jpg'

export default function Word(props) {
    const [wordProgress, setWordProgress] = useState('')

    const textState = ["istyping", "isdeleting"];
    const [typing, setTyping] = useState(textState[0]);
    const [wordIndex, setWordIndex] = useState(0)

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

 

    useEffect(() => {
        const timeout = setTimeout(() => {
            let word = props.words[wordIndex]
            if (typing === "istyping" && wordProgress !== word) {
                setWordProgress(word.slice(0, wordProgress.length + 1));
            }
            else if (wordProgress === word && typing === "istyping") {
                sleep(2000).then(() => {
                    setTyping(textState[1])
                })
            }
            else if ((wordProgress === word && typing === "isdeleting") || typing === "isdeleting") {
                setWordProgress(word.slice(0, wordProgress.length - 1));
                if (wordProgress.length <= 2) {
                    setTyping(textState[0])
                    if(wordIndex === props.words.length - 1){ setWordIndex(0)}
                    else setWordIndex((prev) => prev+1)
                }
            }
        }, 100);
        return () => clearTimeout(timeout);
    }, [wordProgress, typing]);
    return (
        <div className={`blinking cursor ${props.className}`}>
            {wordProgress}
        </div>
    )
}