import React, { useState } from "react";
import "./ImageHolder.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Carousel } from "react-bootstrap";

export default function ImageHolder(props) {
  const [selectedImage, setSelectedImage] = useState(0);
  return (
    <>
      {/* <div className={"imageHolder"}>
        <FaAngleLeft />
        <img
          className={"media " + props.images[selectedImage].class}
          src={props.images[selectedImage].image}
        />
        <FaAngleRight />
      </div> */}
      <Carousel interval={props.interval}>
        {props.images.map((image) => {
          return (
            <Carousel.Item>
              <img className={"media "+ image.class} src={image.image}/>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
}
