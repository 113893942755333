import React, { useEffect, useState } from "react";
import "./Hero.scss";
import profile from "../../static/20201128_144908.jpg";
import Word from "../WordTyper/Word";
import Offset from "../Offset";
import { Button } from "react-bootstrap";
import { FaGithub, FaLinkedin } from "react-icons/fa";
export default function Hero(props) {
  let offset = 1500;
  const [rendered, setRendered] = useState();

  useEffect(() => {
    setTimeout(() => {
      setRendered(true);
    }, offset);
  }, []);
  return (
    <div className="Hero">
      {rendered && (
        <div>
          <div className="Avatar">
            <h3>Hello there,</h3>
            <h1>
              I'm <span className="mainTextContrast">Oran Rohoman</span>
            </h1>

            <Word
              words={[
                "I write programs for the internet.",
                "I like to learn new things.",
                "I build games for fun.",
                "I obsessively read fantasy books.",
              ]}
              className={"h2"}
            />

            <p>
              I am a Software Developer, currently living in Edmonton, Alberta.
              My goal is to continue to build pieces of software for the web.
            </p>
            <div className="buttonGroup">
              <a
                href="https://github.com/OranRohoman/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaGithub />
              </a>
              <a
                href="https://www.linkedin.com/in/oran-rohoman-8804a41a1/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
            </div>
          </div>
          <div className="ProfileHolder">
            <div className="ProfilePic">
              <div />
            </div>
            <div className="ProfileBox1"/>
            <div className="ProfileBox2"/>

          </div>
        </div>
      )}
    </div>
  );
}
