import "./App.scss";
import Navbar from "./components/Navbar.js";
import Sidebar from "./components/SideBar.js";
import Experience from "./components/Experience/Experience";
import TechStack from "./components/TechStack.js";
import Footer from "./components/Footer.js";
import "./components/SideBar.css";
import Hero from "./components/Hero/Hero";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";

import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

import "bootstrap/dist/css/bootstrap.min.css";

const external_style = {
  marginLeft: "1%",
};
const github = {
  width: 50,
  height: 50,
  position: "relative",
  top: 10,
};
const googlePlay = {
  width: 35,
  height: 35,
  position: "relative",
  top: 5,
};

function App() {
  return (
    <div className="mainContent">
      <Navbar />
      <Hero />
      <TechStack />
      <AnimationOnScroll animateIn="animate__fadeInLeft">
        <About />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeInRight">
        <Experience />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeInLeft">
        <Projects />
      </AnimationOnScroll>
    </div>
  );
}

export default App;
