import React from "react";
import { Col, Row } from "react-bootstrap";

import "./about.css";
export default function About() {
  return (
    <div id="ab" className="about">
      <div className="content">
        <div className="profile">
          <Row className="title">
            <Col sm="auto">About Me </Col>
          </Row>
          <div className="description">
            <div>
              <p>
                Ever Since I was a kid, I’ve spent most of my free time on
                computers. What started as a minor obsession with video games
                like Halo, sparked a passion for software, and how the programs
                we use every day work. This passion led me to pursue a
                bachelor's degree in computer science from the University of Alberta, and later a career in
                software development.
              </p>
              <p>
                {" "}
                Currently I'm a software developer at
                <a
                  href={"https://www.eldorwal.com/"}
                  alt=""
                  target="_blank"
                  className="ewr"
                >
                  Eldorwal Registrations
                </a>
                focusing on building high performing software.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
