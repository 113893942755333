import React, { useEffect, useState } from 'react'

export default function Offset(props) {
    const [rendered, setRendered] = useState(false)
    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    useEffect(() => {
        setTimeout(async () => {
            await sleep(props.offset)
            setRendered(true);
            console.log("rendering with offset", props.offset)
        }, props.offset)
    }, [])
    return (
        <>
            {rendered && <>
                {props.childComponent()}
            </>
            }
        </>
    )


}