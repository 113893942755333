import React from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import "./projects.scss";
import ImageHolder from "../ImageHolder/ImageHolder";
import movie1 from "../../static/IndustryIsland/movie1.gif";
import movie2 from "../../static/IndustryIsland/movie2.gif";
import movie3 from "../../static/IndustryIsland/movie3.gif";
import { FaExternalLinkAlt } from "react-icons/fa";
import YoutubeEmbed from "../YoutubeEmbed/YoutubeEmbed";

export default function Projects() {
  let industryIsland = [
    { image: movie1, class: "small" },
    { image: movie2, class: "small" },
    { image: movie3, class: "large" },
  ];
  return (
    <section id={"projects"}>
      <div>
        <Row className="title">
          <Col sm="auto">Projects I'm Proud of</Col>
        </Row>
        <div className="projects">
          <div className="projectitem">
            <ImageHolder interval={5000} images={industryIsland} />
            <div className="projectContent">
              <Row sm="auto">
                <Col>
                  <h1>Industry Island</h1>
                </Col>
                <Col sm="auto" className="external">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.EnB.IndustryIsland"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaExternalLinkAlt />
                  </a>
                </Col>
              </Row>
              <h2 className="position">Lead Developer</h2>
              <p>
                {`Industry Island is a mobile application Developed in Unity
                originally released in June of 2021 on `}
                <a
                  href="https://play.google.com/store/apps/details?id=com.EnB.IndustryIsland"
                  className="industryisland"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {"Google Play."}
                </a>
              </p>
              <p>
                After garnishing Hundreds of downloads, subsequent updates to
                the application were released June of 2023.
              </p>
              <Row style={{ justifyContent: "flex-start" }}>
                {["Unity", "C#", "Firebase"].map((tool) => {
                  return (
                    <Col xs="auto" className="mb-2">
                      <div variant="outline-primary" className="skill_button">
                        <div>{tool}</div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>

          <div className="projectitem">
            <div className="projectContent">
              <Row sm="auto">
                <Col>
                  <h1>Social Distribution</h1>
                </Col>
                <Col sm="auto" className="external">
                  <a
                    href="https://github.com/OranRohoman/CMPUT-404-PROJECT-WORK-W21"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaExternalLinkAlt />
                  </a>
                </Col>
              </Row>
              <h2 className="position">Backend Developer</h2>
              <p>
                {`Social Distribution is was a student project simulating a multi-node social media website. `}
              </p>
              <p>
                Utilizing multiple load balancing backend nodes ensured high
                performance over a large quantity of data.
              </p>
              <Row style={{ justifyContent: "flex-start" }}>
                {["Python", "Django", "Postgresql", "Javascript"].map(
                  (tool) => {
                    return (
                      <Col xs="auto" className="mb-2">
                        <div variant="outline-primary" className="skill_button">
                          <div>{tool}</div>
                        </div>
                      </Col>
                    );
                  }
                )}
              </Row>
            </div>
            <YoutubeEmbed embedID="CqLjEeCpXIc" />
          </div>
        </div>
      </div>
    </section>
  );
}
