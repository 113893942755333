import { Col, Row, Button } from "react-bootstrap";
import "./experience.css";
export default function ExperienceItem(props) {
  return (
    <div className={"expItem"}>
      <h4>{props.timeFrame}</h4>
      <div>
        <div className="expTitle">{props.title || ""} </div>
        <div className="position">{props.header}</div>
        <h4>Responsibilities</h4>
        <div>
          <p>
            {props.tasks}
          </p>
        </div>
        <Row>
          {props.tools.map((tool) => {
            return <Col xs="auto" className='mb-2'>
              <div variant="outline-primary" className="skill_button">
                <div>{tool}</div>
              </div>
            </Col>
          })}
        </Row>
      </div>
    </div>
  );
}
