import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ExperienceItem from "./ExperienceItem";

export default function Experience(props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  let EldorwalDescription = [
    "Contributed maintainable code for a diverse array of internal and client facing projects. ",
    "Worked with a multitude of programing languages, database, source control and project management programs. ",
  ];

  let WCBDescription = `Over the span of multiple Internships working with the network team, 
  Utilizing python and javascript to create maintinance software for network switches, aswell as monitoring application on network traffic.`;

  let items = [
    <ExperienceItem
      shift="left"
      header="Eldorwal Registrations"
      title="Junior Software Developer"
      timeFrame={"Jan 2022 - Present"}
      tasks={EldorwalDescription}
      tools={["React", "JavaScript", "Sequelize", "SQL Server"]}
    />,
    <ExperienceItem
      shift="left"
      header="Workers Compensation Board of Alberta"
      title="Internship"
      timeFrame={"May 2019 - Aug 2020"}
      tasks={WCBDescription}
      tools={["React", "Python"]}
    />,
  ];

  return (
    <section id="exp">
      <div className="content">
        <Row className="title">
          <Col sm="auto">Where I've Worked</Col>
        </Row>
        <div className="experience">
          {items.map((item) => {
            return item;
          })}
        </div>
      </div>
    </section>
  );
}
