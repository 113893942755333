import React, { Component } from "react";

import Offset from "./Offset";
import "./Navbar.scss";
import resume from "../static/Oran Rohoman Resume.pdf";
import icon from "../static/OranIcon.png";
import { Container, Nav, Offcanvas, Navbar } from "react-bootstrap";

// const left ={
//     justifyContent:"flex-start",
//     width:"50px",
//     height:"50px",
//     backgroundColor:"black"
// }

var lastScrollTop = 0;
const CustomNavbar = (props) => {
  const expand = "md";

  return (
    <>
      <Navbar key={expand} expand={expand}  fixed="top" className="bg-body-primary mb-3 navStyles">
        <Container fluid>
          <Navbar.Brand href="#"> <img src={icon} alt="" /></Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3 text-light">
                <Nav.Link href="#ab">
                  <Offset
                    offset={700}
                    childComponent={() => {
                      return <span className={"FadeLeft NavItem"}>About</span>
                    }}
                  />
                </Nav.Link>

                <Nav.Link href="#exp">
                  <Offset
                    offset={600}
                    childComponent={() => {
                      return <span className={"FadeLeft NavItem"}>Experience</span>
                    }}
                  />
                </Nav.Link>
                <Nav.Link href="#projects">
                  <Offset
                    offset={500}
                    childComponent={() => {
                      return <span className={"FadeLeft NavItem"}>Projects</span>
                    }}
                  />
                </Nav.Link>

                <Nav.Link
                  href={resume}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    <Offset
                    offset={400}
                    childComponent={() => {
                      return <span className={"FadeLeft NavItem"}>Resume</span>
                    }}
                  />
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
